<template>
  <a-card title="预览课件">
    <template #extra>
      <a-space>
        <a-button v-if="canCopy" @click="handleShowWarning" type="primary">
          获取
        </a-button>
        <a-button @click="handleGoBack"> 返回 </a-button>
      </a-space>
    </template>
    <div style="position: relative">
      <a-spin
        size="large"
        :spinning="fullscreenLoading"
        :tip="fullscreenLoadingText"
      >
        <a-layout class="body_layout">
          <a-layout-sider width="190" class="treeSelect">
            <div style="padding-left: 20px">
              <a-space>
                <a-breadcrumb style="font-size: 13px">
                  <a-breadcrumb-item>官方课件</a-breadcrumb-item>
                  <a-breadcrumb-item>预览课件</a-breadcrumb-item>
                </a-breadcrumb>
              </a-space>
              <!-- <a-button @click="handleTest">测试</a-button> -->
              <h2>
                <strong v-if="currentTitle?.length < 8">
                  {{ currentTitle }}
                </strong>
                <strong v-else>
                  <a-tooltip :title="currentTitle"
                    >{{ currentTitle?.slice(0, 7) }}...</a-tooltip
                  >
                </strong>
              </h2>
              <h4
                v-if="
                  !store.state.currentPeriods ||
                  store.state.currentPeriods?.title?.length < 6
                "
              >
                <strong
                  >当前课时:{{
                    store.state.currentPeriods?.title || "无"
                  }}</strong
                >
              </h4>
              <h4 v-else>
                <a-tooltip :title="store.state.currentPeriods?.title">
                  <strong
                    >当前课时:{{
                      store.state.currentPeriods?.title?.slice(0, 5)
                    }}...</strong
                  >
                </a-tooltip>
              </h4>
              <div>
                <a-image
                  :src="currentImageSrc"
                  class="bookImage"
                  :preview="false"
                />
              </div>
            </div>
            <a-directory-tree
              :tree-data="treeInfo"
              class="treeBody"
              v-model:expandedKeys="selectedKeys"
              v-model:selectedKeys="currentSelectKey"
              @select="handleSelect"
            >
              <template #chapter> - </template>
            </a-directory-tree>
          </a-layout-sider>
          <a-layout-content style="position: relative">
            <div class="maskItem" v-if="showPPT"></div>
            <div class="noPptItem" v-if="noPPTVisible">
              <a-result status="warning" title="当前教材无可用课件">
                <template #extra>
                  <a-button key="console" type="primary" @click="handleGoBack"
                    >返回</a-button
                  >
                </template>
              </a-result>
            </div>
            <div
              ref="pptistInResource"
              id="pptistInResource"
              class="pptistInResource"
            ></div>
          </a-layout-content>
        </a-layout>
      </a-spin>
    </div>
  </a-card>
</template>

<script>
import { createVNode, onMounted, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import * as service from './service'
import { transToTreeData, getFirstPeriodsPPT, queryNode } from './commonutil'
import { message, Modal } from 'ant-design-vue'
import config from '@/config'
import { eventCenter, event } from '@evideo/frontend-utils'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { PreviewPptModeRuntimeConfig } from '@/components/ppt-editor/config'
import { shareModeEnum } from '@/utils/dataMap'

export default {
  beforeRouteLeave (to, from, next) {
    if (from.path.indexOf('preview')) {
      eventCenter.send(event.COMMON_UNLOAD_MICRO_APP, {
        app: config.pptist.name
      })
    }
    setTimeout(() => {
      next()
    }, 0)
  },
  setup (props) {
    // 加载状态
    const fullscreenLoading = ref(false)
    const fullscreenLoadingText = ref('')

    const currentTitle = ref('')

    const currentImageSrc = ref('')

    const store = useStore()

    const router = useRouter()

    const showPPT = ref(true)

    const route = useRoute()
    const bookId = route.params.id

    const bookInfo = ref()

    const pptistInResource = ref()

    const treeInfo = ref([])

    const currentSelectKey = ref([])

    const treeList = ref([])

    const selectedKeys = ref([])

    const canCopy = ref(false)

    const noPPTVisible = ref(false)

    const handleGoBack = () => {
      router.push('/resource/teaching-material')
    }

    const getBookInfo = async () => {
      try {
        bookInfo.value = await service.getOfficialBookInfo(bookId)
        currentImageSrc.value = bookInfo.value.poster
        currentTitle.value = bookInfo.value.name
      } catch (error) {
        console.error(error)
        message.error('初始化数据失败，请刷新页面重试')
      }
    }

    const getTreeInfo = async () => {
      try {
        const list = await service.getOfficialTreeInfo(bookId)
        treeList.value = list
        treeInfo.value = transToTreeData(list)
        console.log(treeInfo.value)
      } catch (error) {
        console.error(error)
        message.error('初始化数据失败，请刷新页面重试')
      }
    }

    // 打开一份ppt
    const handleOpenPPT = (params) => {
      showPPT.value = false
      console.log('handleOpenPPT-params', params)
      eventCenter.send(event.PPTIST_LOAD_PPT, {
        url: params.section_resource?.url || undefined,
        runtime: {
          state: {
            screening: true,
            screeningType: 'preview',
            exitScreenType: 'preview'
          },
          extData: {
            coursewareId: params.section_resource?.id ?? 0,
            coursewareSource: 'official'
          }
        }
      })
      store.commit('setCurrentPeriods', params)
      console.log(params, store.state.currentPeriods)
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 500)
    }

    // 展示没有ppt的蒙层
    const showNoPPT = () => {
      noPPTVisible.value = true
    }

    // 加载ppt子应用
    const loadPptistApp = () => {
      const pptEntryUrl = config.pptist.entry
      const runtime = PreviewPptModeRuntimeConfig

      eventCenter.send(event.COMMON_LOAD_MICRO_APP, {
        app: config.pptist.name,
        el: pptistInResource.value,
        entry: pptEntryUrl,
        args: { token: store.state.token, microAppUrl: pptEntryUrl, appName: config.pptist.name, runtime },
        screening: true,
        screenType: 'preview',
        exitScreenType: 'preview'
      }, res => {
        if (res) {

        }
      })
      eventCenter.sub(`${config.pptist.name}-load-ok`, () => {
        const firstPath = []
        // 选出第一份ppt
        const firstPPT = getFirstPeriodsPPT(treeInfo.value, firstPath)
        console.log(firstPPT)
        if (firstPPT) {
          store.commit('setCurrentPeriods', firstPPT)
          handleOpenPPT(firstPPT)
          currentSelectKey.value.push(firstPPT.id)
          selectedKeys.value = [...firstPath]
          canCopy.value = !!firstPPT.section_resource && firstPPT.section_resource.share_mode !== shareModeEnum.NOCOPY
          showFullScreenLoading(false)
        } else {
          canCopy.value = false
          message.error('该教材暂无可用课件，请选择其他教材')
          showNoPPT()
          showFullScreenLoading(false)
        }
      })
    }

    // 全局等待
    const showFullScreenLoading = (value, text = '') => {
      fullscreenLoading.value = value
      fullscreenLoadingText.value = text
    }

    // 注册监听事件
    const registerListener = () => {
      const isFullscreen = () => (
        document.mozFullScreen ||
        document.webkitIsFullScreen ||
        document.webkitFullScreen
      )

      // 全屏事件的处理
      eventCenter.sub(event.COMMON_FULL_SCREEN, res => {
        const element = pptistInResource.value
        if (res.fullScreen === true) {
          if (!isFullscreen()) {
            // 进入全屏
            if (element.requestFullScreen) {
              element.requestFullScreen()
            } else if (element.mozRequestFullScreen) {
              element.mozRequestFullScreen()
            } else if (element.webkitRequestFullscreen) {
              element.webkitRequestFullscreen()
            } else if (element.msRequestFullscreen) {
              element.msRequestFullscreen()
            }
          }
        } else {
          if (document.exitFullScreen) {
            document.exitFullScreen()
          } else if (document.msExitFullScreen) {
            document.msExitFullScreen()
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen()
          }
        }
      })
    }

    const handleSelect = (select, e) => {
      if (select.length < 1) {
        return
      }
      const treeNode = queryNode(treeInfo.value, select[0])
      if (treeNode) {
        showFullScreenLoading(true, '加载中')
        canCopy.value = !!treeNode.section_resource && treeNode.section_resource.share_mode !== shareModeEnum.NOCOPY
        handleOpenPPT(treeNode)
        showFullScreenLoading(false)
      }
    }

    const handleCopy = async () => {
      const value = store.state.currentPeriods
      try {
        showFullScreenLoading(true)
        const params = { official_courseware_id: value.resource_id }
        service.addDownloadLog(value.id)
        const data = await service.copyOfficialCourseware(params)
        console.log(data)
        message.success('添加到我的课件成功')
      } catch (error) {
        console.log(error)
        message.error('添加到我的课件失败，请重试')
      } finally {
        showFullScreenLoading(false)
      }
    }
    const handleShowWarning = () => {
      Modal.confirm({
        title: '是否确认将课件添加到我的课件中',
        icon: createVNode(ExclamationCircleOutlined),
        closable: true,
        onOk: () => handleCopy(),
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }

    const handleHideMenu = () => {
      eventCenter.send(event.COMMON_CHANGE_COLLPASED, { collapsed: true })
    }

    // 初始化数据
    onMounted(async () => {
      store.commit('setCurrentPeriods', undefined)
      handleHideMenu()
      showFullScreenLoading(true, '初始化中')
      // 获取教材信息
      await getBookInfo()
      await getTreeInfo()
      registerListener()
      loadPptistApp()
    })

    return {
      store,
      showPPT,
      currentTitle,
      currentImageSrc,
      fullscreenLoading,
      fullscreenLoadingText,
      pptistInResource,
      treeInfo,
      currentSelectKey,
      selectedKeys,
      canCopy,
      noPPTVisible,
      handleGoBack,
      handleSelect,
      handleShowWarning
    }
  }
}
</script>

<style lang="less">
.body_layout {
  height: calc(100vh - 100px);
}

.maskItem {
  z-index: 2;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgb(241 242 246);
}

.noPptItem {
  z-index: 99;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: white;
}

.treeSelect {
  // padding-left: 20px;
  padding-top: 20px;
  background: #fff;
  height: 100%;
  border-right: 1px solid rgb(241 242 246);
  box-shadow: 1px 0 4px rgb(0 21 41 / 18%);
  z-index: 2;
}

/*定义滚动条轨道 内阴影+圆角*/
.treeSelect::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
  border-radius: 10px;
  background-color: rgba(240, 240, 240, 0.5);
}

.treeSelect ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(240, 240, 240, 1);
}

/*定义滑块 内阴影+圆角*/
.treeSelect ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
  background-color: #c1c1c1;
}

.bookImage {
  width: 150px;
  height: 200px;
}

.pptistInResource {
  width: 100%;
  height: 100%;
}

.treeBody {
  overflow-y: auto;
  overflow-x: auto;
  margin-top: 20px;
  padding-bottom: 20px;
  height: 40vh;
}

.leftPlaceholder {
  padding-left: 10px;
}

.ant-tree li span.ant-tree-switcher {
  width: 15px;
}

.ant-tree li span.ant-tree-switcher,
.ant-tree li span.ant-tree-iconEle {
  width: 16px;
  margin-right: 3px;
}

.ant-card-body {
  padding: 24px 10px;
}

.ant-tree li ul {
  padding-left: 10px;
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper{
  color: black
}
</style>
