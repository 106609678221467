const TreeItemType = {
  Chapter: 0,
  Periods: 1
}

export const transToTreeData = (list: any[]) => {
  if (!list || list.length < 1) {
    return list
  }
  const newList:any[] = []
  list.forEach((item:any) => {
    const newNode:any = {
      id: item.id,
      key: item.id,
      title: item.name,
      type: item.type
    }
    if (item.type === TreeItemType.Chapter) {
      newNode.children = transToTreeData(item.children || [])
      newNode.slot = 'chapter'
    } else if (item.type === TreeItemType.Periods) {
      newNode.resource_id = item.resource_id
      newNode.section_resource = item.section_resource
      newNode.isLeaf = true
      newNode.slot = 'periods'
    }
    newList.push(newNode)
  })
  return newList
}

export const getFirstPeriodsPPT = (list: any[], path: any[] = []) => {
  let result
  for (let i = 0; i < list.length; i += 1) {
    const item = list[i]
    if (item.type === TreeItemType.Periods) {
      return item
    }

    if (item.type === TreeItemType.Chapter) {
      path.push(item.id)
      result = getFirstPeriodsPPT(item.children || [], path)
      if (result === undefined) {
        path.pop()
      } else {
        return result
      }
    }
  }
  return undefined
}

export const queryNode = (list: any[], id:any) => {
  let result
  for (let i = 0; i < list.length; i += 1) {
    const item = list[i]
    if (item.id === id) {
      if (item.type === TreeItemType.Periods) {
        return item
      }
    }

    if (item.type === TreeItemType.Chapter) {
      result = queryNode(item.children || [], id)
      if (result) {
        return result
      }
    }
  }
  return result
}
